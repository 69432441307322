
export interface NotificationObject {
  contextName: string | undefined;
  action: string | undefined;
  recvTime: string | undefined;
  read: boolean | undefined;
}

export type NotificationsResponse = Array<NotificationObject>;

// Función para formatear la fecha en formato local
const formatDateString = (dateString?: string): string => {
  if (!dateString) {
      console.error('Fecha indefinida o nula.');
      return '';
  }

  try {
      // Crear un objeto Date a partir de la cadena de fecha en formato UTC
      const utcDate = new Date(dateString); // Asegurarse de que se trata como UTC

      // Verificar si la fecha es válida
      if (isNaN(utcDate.getTime())) {
          console.error('Formato de fecha no válido:', dateString);
          return '';
      }

      // Convertir automáticamente a la zona horaria local y formatear manualmente
      const year = utcDate.getFullYear();
      const month = String(utcDate.getMonth() + 1).padStart(2, '0');
      const day = String(utcDate.getDate()).padStart(2, '0');
      const hours = String(utcDate.getHours()).padStart(2, '0');  // Esto usa la hora local
      const minutes = String(utcDate.getMinutes()).padStart(2, '0');
      const seconds = String(utcDate.getSeconds()).padStart(2, '0');

      // Construir la fecha en formato local similar a ISO 8601 (con la "T" y sin zona horaria)
      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  } catch (error) {
      console.error('Error al formatear la fecha:', error);
      return '';
  }
};


// Implementación de makeNotification que utiliza formatDateString
export const makeNotification = (event: any): NotificationObject | undefined => {
  if (typeof event !== "string") {
      try {
          const recvTime: string = event.recvTime;
          console.log('recvTime (original): ', recvTime);

          // Formatear la fecha en formato local usando formatDateString
          const localDate = formatDateString(recvTime);
          console.log('recvTime (local): ', localDate);

          let entidad: string;

          if (event.flanco.toUpperCase() === "SUBIDA") {
              entidad = `alerta ON temperatura: ${event.temperatura}`;
          } else if (event.flanco.toUpperCase() === "BAJADA") {
              entidad = `alerta OFF temperatura: ${event.temperatura}`;
          } else {
              throw new Error("Formato de evento no válido");
          }

          return {
              contextName: "Contexto: Isla de Flores\n" + event.ruleTagName,
              action: entidad,
              recvTime: localDate,  // Mostrar la fecha en formato local
              read: false,
          };
      } catch (error: any) {
          console.error(`[ERROR][MAKENOTIFICATION] al procesar el evento: ${error.message}`);
      }
  }

  return undefined;
};
