import React from "react";
import Card from "../UI/Card";
import classes from "./IconWithValue.module.css";

interface IconWithValueProps {
  title: string; // Título como "TEMPERATURA ACTUAL"
  value: number; // Valor dinámico como 21.8
  unit: string; // Unidad como "°C"
  icon: React.ReactNode; // Ícono pasado como un componente
}

const IconWithValue: React.FC<IconWithValueProps> = ({ title, value, unit, icon }) => {
  return (
    <Card title={title}>
      <div className={classes.container}>
        <div className={classes.icon}>{icon}</div>
        <div className={classes.value}>
          {value} <span className={classes.unit}>{unit}</span>
        </div>
      </div>
    </Card>
  );
};

export default IconWithValue;
