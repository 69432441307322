import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import SpeedOutlined from '@mui/icons-material/SpeedOutlined';
import SecurityUpdateGoodIcon from '@mui/icons-material/SecurityUpdateGood';
import HistoryToggleOffOutlined from '@mui/icons-material/HistoryToggleOffOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";
import classes from './listItems.module.css';

function MainListItems() {

  let navigate = useNavigate();
  const routeChange = (path: string) => {
    navigate(path);
  }
  return (
    <div className={classes.listEl}>
      {/* <ListItemButton onClick={() => routeChange('users')}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItemButton> */}
      <ListItemButton onClick={() => routeChange('devices')}>
        <ListItemIcon>
          <SpeedOutlined />
        </ListItemIcon>
        <ListItemText primary="Monitoreo" />
      </ListItemButton>
      <ListItemButton onClick={() => routeChange('history')}>
        <ListItemIcon>
          <HistoryToggleOffOutlined />
        </ListItemIcon>
        <ListItemText primary="Análisis Histórico" />
      </ListItemButton>
    </div>
  )
}

export default MainListItems;
