import { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LoginPage from "./pages/LoginPage";
import UsersPage from "./pages/UsersPage";
import DevicesPage from "./pages/DevicesPage";
import HistoricalDataPage from "./pages/HistoricalDataPage";
import UsersCreatePage from "./pages/UsersCreatePage";
import UsersEditPage from "./pages/UsersEditPage";
import Layout from "./components/Layout/Layout";
import AuthContext from "./store/auth-context";
import { TitleContext, ValueContextType } from "./components/Context";
import { NotificationProvider } from "./NotificationContext";
import { HistoricalDataProvider } from "./HistoricalDataContext";
import { ProtectedRoute } from "./ProtectedRoute";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MessageFeedProvider } from "./MessageFeedContext";

function App() {
  const authCtx = useContext(AuthContext);
  const [title, setTitle] = useState<ValueContextType | any>("");

  const navigate = useNavigate();

  // Axios interceptor for adding Authorization headers
  axios.interceptors.request.use(
    (config) => {
      const token = authCtx.token;
      if (token && config.headers != undefined) {
        config.headers["Authorization"] = "Bearer " + token;
        config.headers["Content-Type"] = "application/json";
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  let element;
  const dev: boolean = process.env.NODE_ENV.trim() === "development";
  if (!dev) {
    element = (
      <ProtectedRoute>
        <NotificationProvider>
          <Layout />
        </NotificationProvider>
      </ProtectedRoute>
    );
  } else {
    element = (
      <NotificationProvider>
        <Layout />
      </NotificationProvider>
    );
  }

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      <NotificationProvider>
        <HistoricalDataProvider>
          <MessageFeedProvider> {/* Agregamos el proveedor del feed aquí */}
            <Routes>
              <Route element={element}>
                <Route path="/users" element={<UsersPage />} />
                <Route path="/users-create" element={<UsersCreatePage />} />
                <Route path="/users-edit/:user_id" element={<UsersEditPage />} />
                <Route path="/devices" element={<DevicesPage />} /> {/* Ahora envuelto por el contexto del feed */}
                <Route path="/history" element={<HistoricalDataPage />} />
              </Route>
              <Route path="*" element={<LoginPage />} />
            </Routes>
          </MessageFeedProvider>
        </HistoricalDataProvider>
      </NotificationProvider>
    </TitleContext.Provider>
  );
}

export default App;
