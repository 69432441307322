import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "../components/UI/Card.module.css";
import { useHistoricalDataContext } from "../HistoricalDataContext";

const DateRangePicker: React.FC = () => {
  const {
    alertStartDate,
    alertEndDate,
    setAlertStartDate,
    setAlertEndDate,
    setGeneralStartDate,
    setGeneralEndDate,
  } = useHistoricalDataContext();

  const handleStartDateChange = (date: Date) => {
    const now = new Date();
    if (date > now) {
      console.warn("La fecha de inicio seleccionada está en el futuro. Ajustando al momento actual.");
      date = now; // Ajustar a la fecha/hora actual
    }
    setAlertStartDate(date);
    setGeneralStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
    const now = new Date();
    if (date > now) {
      console.warn("La fecha de fing seleccionada está en el futuro. Ajustando al momento actual.");
      date = now; // Ajustar a la fecha/hora actual
    }
    setAlertEndDate(date);
    setGeneralEndDate(date);
  };

  return (
    <div
      className={classes.dateRangePicker_input}
      style={{ display: "flex", alignItems: "center" }}
    >
      <div style={{ marginRight: "10px" }}>
        <DatePicker
          selected={alertStartDate}
          onChange={handleStartDateChange}
          showTimeSelect
          timeFormat="HH:mm:ss"
          timeIntervals={15}
          dateFormat="yyyy-MM-dd HH:mm:ss"
          maxDate={new Date()}  // Limita la selección al día de hoy
        />
      </div>
      <div>
        <span>-</span>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <DatePicker
          selected={alertEndDate}
          onChange={handleEndDateChange}
          showTimeSelect
          timeFormat="HH:mm:ss"
          timeIntervals={15}
          dateFormat="yyyy-MM-dd HH:mm:ss"
          maxDate={new Date()}  // Limita la selección al día de hoy
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
