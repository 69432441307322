import axios from "axios";
import { useEffect, useState } from "react";

interface backResponseEl {
  temperatura: number;
  conductividad: number;
  turbidez: number;
  latitud: number;
  longitud: number;
  recvTime: Date;
}

export interface DataResponse {
  temperatura: number;
  conductividad: number;
  turbidez: number;
  latitud: number;
  longitud: number;
  recvTime: Date;
}

export type GeneralServiceResponse = DataResponse[];

export function useGetGeneralData(
  context: string,
  fecha_desde: string,
  fecha_hasta: string,
): GeneralServiceResponse {
  const [data, setData] = useState<GeneralServiceResponse>([]);

  useEffect(() => {
    if (fecha_desde && fecha_hasta) {
      let didCancel = false;
      axios
        .get(
          `${
            process.env.REACT_APP_API_HOST || "http://localhost:8080"
          }/api/v1/contexts/${context}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            params: {
              fecha_desde: fecha_desde,
              fecha_hasta: fecha_hasta,
            },
          }
        )
        .then(async (response) => {
          let dataResponse: GeneralServiceResponse = [];
          response.data.forEach((dataBack: backResponseEl) => {
            const newDataEl: DataResponse = {
              temperatura: dataBack.temperatura,
              conductividad: dataBack.conductividad,
              turbidez: dataBack.turbidez,
              latitud: dataBack.latitud,
              longitud: dataBack.longitud,
              recvTime: dataBack.recvTime,
            };
            dataResponse.push(newDataEl);
          });
          
          if (dataResponse.length > 0) {
            setData(dataResponse);
          } 
        })
        .catch(function (error) {
          console.log("error", error);
          // throw new Error(error || "Could not fetch data.");
        })
      return () => {
        didCancel = true;
      };
    }
  }, [fecha_desde, fecha_hasta]);
  return data;
}
