import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import "chartjs-adapter-moment";
import classes from "./HistoricalEvolutionGraph.module.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
  scales: {
    x: {
      type: "time" as const,
      gridLines: {
        lineWidth: 2,
      },
      time: {
        unit: "hour" as const, // Cambiar a "hour" para mostrar las horas
        unitStepSize: 1,
        displayFormats: {
          hour: "MMM DD HH:mm", // Formato en 24 horas (HH:mm)
          day: "MMM DD",
          week: "MMM DD",
          month: "MMM DD",
          quarter: "MMM DD",
          year: "MMM DD",
        },
        tooltipFormat: "YYYY-MM-DD HH:mm", // Formato para el tooltip (24 horas)
      },
    },
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
  },
};


interface HistoricalEvolutionGraphProps {
  title: string;
  dataSet: DataSet[];
  borderColor: string;
  backgroundColor: string;
  specificDate: Date | undefined;
}

interface DataSet {
  value: number;
  recvTime: Date;
}

const HistoricalEvolutionGraph = ({
  title,
  dataSet,
  specificDate,
  borderColor,
  backgroundColor,
}: HistoricalEvolutionGraphProps) => {
  let specificIndex: number | undefined;
  if (specificDate !== undefined) {
    const specificDateObj = new Date(specificDate);
    // Eliminar milisegundos
    specificDateObj.setMilliseconds(0);  // Establecer milisegundos a 0
    
    specificIndex = dataSet.findIndex((data) => {
      const dataDate = new Date(data.recvTime);
      // Eliminar milisegundos
      dataDate.setMilliseconds(0);  // Establecer milisegundos a 0
      console.log(`Comparing: ${specificDateObj.getTime()} === ${dataDate.getTime()}`);
      return dataDate.getTime() === specificDateObj.getTime();  
    });
  }

  const transformData = (
    dataSet: DataSet[],
    label: string,
    borderColor: string,
    backgroundColor: string
  ) => {
    return {
      label,
      data: dataSet.map((data) => ({
        x: new Date(data.recvTime),
        y: data.value,
      })),
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      yAxisID: "y",
      pointRadius: dataSet.map((_, index) => {
        const conditionMet = typeof specificIndex === "number" && index === specificIndex;
        //console.log(`index: ${index}, specificIndex: ${specificIndex}, conditionMet: ${conditionMet}`);
        return conditionMet ? 5 : 0;
      }),
      pointBackgroundColor: dataSet.map(
        (_, index) => (index === specificIndex ? "#007FFF" : backgroundColor)
      ),
    };
  };

  const chartData = {
    label: dataSet.map((data) => new Date(data.recvTime)),
    datasets: [transformData(dataSet, title, borderColor, backgroundColor)],
  };

  return (
    <section>
      <div className={classes.card}>
        <div className={classes.card_content}>
          <div className={classes.graphContainer}>
            <Line options={options} data={chartData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HistoricalEvolutionGraph;
