import { Fragment, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal } from 'react';

import MainNavigation from './MainNavigation';

const Layout = () => {
  return (
    <Fragment>
      <MainNavigation />
    </Fragment>
  );
};

export default Layout;
