import { useNavigate  } from 'react-router-dom';
import useHttp from '../severApi/UseHttp';
import { useContext, useState, useEffect, useCallback } from 'react';
import {UsersForm} from '../components/Users/UsersForm';
import { createUser } from '../severApi/ApiUsers';
import AuthContext from '../store/auth-context';
import getErrorMessage from '../components/Utils'
import AppPermission from '../components/Classes/AppPermission'

const NewUser = () => {
  const authCtx = useContext(AuthContext);
  const [error, setError] = useState<string | null>(null);
  const [permissions, setPermissions] = useState<AppPermission>();

  const addUserHandler = useCallback(async (userData:any) => {
    try {

      console.log("addUserHandler: " )

      // TODO 
      // Ver como evitar pasar el token en cada funcion y que el token se use en api-users
      const data = await createUser(authCtx.token, userData) ;

    } catch (error) {
      setError(getErrorMessage(error));
    }
    // setIsLoading(false);
  }, []);
  
  return <UsersForm saveUserHandler={addUserHandler}
                    permissions={permissions}/>;
};

export default NewUser;