import { Navigate } from "react-router-dom";
import AuthContext from './store/auth-context';
import { useContext } from 'react';


export const ProtectedRoute = ({ children }: any) => {
  const authCtx = useContext(AuthContext);

  if (!authCtx.isLoggedIn) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};