import Card from "../UI/Card";
import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from "chart.js";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
  scales: {
    x: {
      type: "time" as const,
      gridLines: {
        lineWidth: 2,
      },
      time: {
        unit: "minute" as const,
        displayFormats: {
          minute: "HH:mm:ss",
          hour: "HH:mm",
          day: "MMM DD",
        },
      },
    },
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
    },
  },
};

const RealTimeGraph = (props: {
  title: string;
  label: string;
  borderColor: string;
  backgroundColor: string;
  dataPoint: { timestamp: string; value: number } | null; // Recibe los datos por props
}) => {
  const [data, setData] = useState({
    labels: [] as Date[],
    datasets: [] as any[],
  });

  useEffect(() => {
    if (props.dataPoint) {
      const { timestamp, value } = props.dataPoint;

      // Transformamos el timestamp en una fecha válida
      const newDate = new Date(timestamp);
      if (isNaN(newDate.getTime())) {
        console.error("Formato de fecha no válido:", timestamp);
        return;
      }

      setData((prevData) => {
        // Verificamos que no haya datos duplicados
        if (
          prevData.labels.length === 0 ||
          newDate.getTime() > prevData.labels[prevData.labels.length - 1].getTime()
        ) {
          const newLabels = [...prevData.labels, newDate];
          const newDatasets = [
            {
              label: props.label,
              data: [
                ...(prevData.datasets[0]?.data || []),
                { x: newDate, y: value },
              ],
              borderColor: props.borderColor,
              backgroundColor: props.backgroundColor,
              yAxisID: "y",
              pointRadius: 0,
            },
          ];

          return {
            labels: newLabels,
            datasets: newDatasets,
          };
        } else {
          return prevData;
        }
      });

      console.log("Dato agregado al gráfico:", { timestamp, value });
    }
  }, [props.dataPoint]);

  return (
    <section>
      <Card title={props.title}>
        <div>
          {data.labels.length === 0 && "No hay datos para mostrar."}
          {data.labels.length > 0 && <Line options={options} data={data} />}
        </div>
      </Card>
    </section>
  );
};

export default RealTimeGraph;
