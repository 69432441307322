import AppPermission from '../components/Classes/AppPermission'

let base_url = process.env.REACT_APP_API_HOST + "/api/v1/"

export async function getAppPermissions(token:any) {

    // TODO
    // Ver como evitar recibir el token en cada funcion y que el token obtenga en este archivo
    // const AuthCtx = useContext(AuthContext);

    const response = await fetch(base_url + 'app_permissions/', {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || 'Could not fetch app_permissions.');
    }

    const transformedPermissions = [];

    for (const key in data) {
      let appPermission =  new AppPermission()
      appPermission.alias = data[key]['alias']
      appPermission.id = data[key]['id']
    //   const userObj = {
    //     id: key,
    //     ...data[key],
    //   };

        transformedPermissions.push(appPermission);
    }

    return transformedPermissions;
  }
