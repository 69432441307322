import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useMessageFeed } from "../MessageFeedContext";
import RealTimeGraph from "../components/Devices/RealTimeGraph";
import IconWithValue from "../components/Devices/IconWithValue";
import Card from "../components/UI/Card";
import { FaTemperatureHigh, FaWater, FaBatteryHalf, FaTachometerAlt } from "react-icons/fa"; // Asegúrate de importar el ícono adecuado
import DashboardIcon from "../components/UI/DashboardIcon";

const DevicesPage = () => {
  const { messageFeed } = useMessageFeed(); // Obtenemos los mensajes del contexto

  // Función para obtener el dato más reciente de un atributo
  const getLatestValue = (key: string) => {
    if (messageFeed.length === 0) {
      return null; // Retornar null si el feed está vacío
    }
    const latestMessage = messageFeed[messageFeed.length - 1]; // Último mensaje
    return latestMessage[key] ?? null; // Devolver el valor de la clave o null
  };

  // Obtenemos los valores más recientes para cada métrica
  const temperatura = getLatestValue("temperatura") || 0;
  const conductividad = getLatestValue("conductividad") || 0;
  const voltaje = getLatestValue("voltaje_device") || 0;
  const timestamp = getLatestValue("timestamp");

  // Log para verificar los valores actuales
  useEffect(() => {
    console.log("Últimos valores:");
    console.log("Temperatura:", temperatura);
    console.log("Conductividad:", conductividad);
    console.log("Voltaje:", voltaje);
    console.log("Timestamp:", timestamp);
  }, [messageFeed]);

  return (
    <div>
    <Card title="ISLA DE FLORES">
      {/* Header */}
      <Grid container spacing={0} columns={12} justifyContent="space-between" alignItems="center">
        <Grid item xs="auto">
          <DashboardIcon title="MONITOREO" />
        </Grid>
      </Grid>
      
      {/* Contenido principal */}
      <Grid container spacing={3}>
        {/* Fila para Temperatura */}
        <Grid item xs={10}>
          <RealTimeGraph
            title="EVOLUCIÓN DE TEMPERATURA"
            label="Temperatura"
            borderColor="#18265a"
            backgroundColor="#18265a"
            dataPoint={
              temperatura && timestamp
                ? { timestamp: new Date(timestamp).toISOString(), value: temperatura }
                : null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <IconWithValue
            title="TEMPERATURA ACTUAL"
            value={temperatura}
            unit="°C"
            icon={<FaTemperatureHigh />}
          />
        </Grid>

        {/* Fila para Conductividad */}
        <Grid item xs={10}>
          <RealTimeGraph
            title="EVOLUCIÓN DE CONDUCTIVIDAD DEL AGUA"
            label="Conductividad"
            borderColor="#C21292"
            backgroundColor="#C21292"
            dataPoint={
              conductividad && timestamp
                ? { timestamp: new Date(timestamp).toISOString(), value: conductividad }
                : null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <IconWithValue
            title="CONDUCTIVIDAD ACTUAL"
            value={conductividad}
            unit="µS/cm"
            icon={<FaWater />}
          />
        </Grid>

        {/* Fila para Voltaje */}
        <Grid item xs={10}>
          <RealTimeGraph
            title="EVOLUCIÓN DEL VOLTAJE DEL DISPOSITIVO"
            label="Voltaje"
            borderColor="#FFA732"
            backgroundColor="#FFA732"
            dataPoint={
              voltaje && timestamp
                ? { timestamp: new Date(timestamp).toISOString(), value: voltaje }
                : null
            }
          />
        </Grid>
        <Grid item xs={2}>
          <IconWithValue
            title="VOLTAJE ACTUAL"
            value={voltaje}
            unit="V"
            icon={<FaBatteryHalf />}
          />
        </Grid>
      </Grid>
    </Card>
    </div>
    
  );
};

export default DevicesPage;
