import React, { createContext, useContext, useState, ReactNode } from "react";

export interface NotificationObject {
    contextName: string | undefined;
    action: string | undefined;
    recvTime: string | undefined;
    read: boolean | undefined;
}

interface NotificationContextProps {
  notifications: NotificationObject[];
  addNotification: (notification: NotificationObject) => void;
  clearNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotificationContext debe ser utilizado dentro de NotificationProvider");
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationObject[]>([]);

  const addNotification = (notification: NotificationObject) => {
    setNotifications((prevNotifications) => [notification, ...prevNotifications]);
  };

  const clearNotifications = () => {
    setNotifications([]);
  };

  const contextValue: NotificationContextProps = {
    notifications,
    addNotification,
    clearNotifications,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
    </NotificationContext.Provider>
  );
};
