import React, { createContext, useContext, useState } from "react";

// Define el tipo del contexto
interface MessageFeedContextType {
  messageFeed: any[]; // Ajusta el tipo según tus necesidades
  addMessageToFeed: (message: any) => void;
}

// Crear el contexto con un valor predeterminado explícito
const MessageFeedContext = createContext<MessageFeedContextType|undefined>(undefined);

// Hook para usar el contexto
export const useMessageFeed = () => {
  const context = useContext(MessageFeedContext);
  if (!context) {
    throw new Error("useNotificationContext debe ser utilizado dentro de NotificationProvider");
  }
  return context;
}

// Proveedor del contexto
export const MessageFeedProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [messageFeed, setMessageFeed] = useState<any[]>([]);

  // Función para agregar mensajes al feed
  const addMessageToFeed = (message: any) => {
    setMessageFeed((prev) => [...prev, message]);
  };

  const contextValue: MessageFeedContextType = {
    messageFeed,
    addMessageToFeed,
  };

  return (
    <MessageFeedContext.Provider value={contextValue}>
      {children}
    </MessageFeedContext.Provider>
  );
};
