import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import classes from "./DataTableHistory.module.css";

const customIcon = new L.Icon({
  iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
  iconSize: [20, 34], // tamaño del icono
  iconAnchor: [12, 41], // punto del icono que corresponderá a la ubicación del marcador
  popupAnchor: [1, -34], // punto desde el que se mostrará el popup
  shadowSize: [35, 35] // tamaño de la sombra
});

export const MapComponent: React.FC = () => {
  
  // Coordenadas Isla de Flores
  const position: [number, number] = [-34.9423, -55.9258];

  // Añade aquí las coordenadas de tus puntos
  const markers: [number, number][] = [
    [-34.94390, -55.93246]
  ];

  return (
    <div className={classes.card}>
      <div className={classes.card_content}>
        <div style={{ width: "100%" }}>
          <MapContainer
            center={position}
            zoom={15.4}
            style={{ height: "600px", width: "100%" }}
            attributionControl={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />

            {markers.map((markerPosition, idx) => (
              <Marker key={idx} position={markerPosition} icon={customIcon}>
                <Popup>
                  Un punto de interés.{" "}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};
