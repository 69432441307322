import UserData from '../components/Classes/UserData';

let base_url = process.env.REACT_APP_API_HOST + "/api/v1/"

export async function getUsers() {

  const response = await fetch(base_url + 'users/', {
      method: 'GET',
      headers: {}
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch users.');
  }

  const transformedUsers = [];

  for (const key in data) {
    const userObj = {
      id: key,
      ...data[key],
    };

    transformedUsers.push(userObj);
  }

  return transformedUsers;
}


export async function createUser(token:any, userData:any) {

  const response = await fetch(base_url + 'users/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        "first_name": userData.firstName,
        "last_name": userData.lastName,
        "email": userData.email,
        "password": userData.firstName
      })
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch users.');
  }

  const transformedUsers = [];

  for (const key in data) {
    const userObj = {
      id: key,
      ...data[key],
    };

    transformedUsers.push(userObj);
  }

  return transformedUsers;
}


export async function updateUser(token:any, userData:UserData) {

  const response = await fetch(base_url + 'users/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify({
        "id": userData.id,
        "first_name": userData.firstName,
        "last_name": userData.lastName,
        "email": userData.email
      })
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not update user.');
  }

  return data;
}

export async function getUser(token:any, user_id:string) {

  // TODO
  // Ver como evitar recibir el token en cada funcion y que el token obtenga en este archivo
  // const AuthCtx = useContext(AuthContext);

  const response = await fetch(base_url + 'users/' + user_id, {
      method: 'GET',
      headers: {
      'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message || 'Could not fetch users.');
  }

  let user = new UserData();
  user.id = data.id;
  user.firstName = data.first_name;
  user.lastName = data.last_name
  user.email = data.email
  return user;
}


