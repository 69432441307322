import "react-datepicker/dist/react-datepicker.css";
import Grid from "@mui/material/Grid";
import DashboardIcon from "../components/UI/DashboardIcon";
import { ServiceResponse, useGetAlerts } from "../hooks/useGetAlerts";
import { useEffect, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import DataTableHistory from "../components/History/DataTableHistory";
import { useHistoricalDataContext } from "../HistoricalDataContext";
import HistoricalEvolutionGraph from "../components/History/HistoricalEvolutionGraph";
import {
  GeneralServiceResponse,
  useGetGeneralData,
} from "../hooks/useGetGeneralData";
import Card from "../components/UI/Card";
import { MapComponent } from "../components/History/MapComponent";

const HistoricalDataPage = () => {
  const {
    alertStartDate,
    alertEndDate,
    generalStartDate,
    generalEndDate,
    selectedDate,
    filterGeneralDate,
    clearFilterGeneralDate,
  } = useHistoricalDataContext();

  useEffect(() => {
    handleAlertDateChange(alertStartDate, alertEndDate);
  }, [alertStartDate, alertEndDate]);

  useEffect(() => {
    handleGeneralDateChange(generalStartDate, generalEndDate);
  }, [generalStartDate, generalEndDate]);

  const [loadedData, setloadedData] = useState(false);

  const [loadingData, setLoadingData] = useState(true);

  const startLoading = () => setLoadingData(true);

  const stopLoading = () => setLoadingData(false);

  const hasLoadedData = () => setloadedData(true);

  const noLoadedData = () => setloadedData(false);

  const [formatAlertStartDate, setFormatAlertStartDate] = useState<string>("");

  const [formatAlertEndDate, setFormatAlertEndDate] = useState<string>("");

  const [formatGeneralStartDate, setFormatGeneralStartDate] =
    useState<string>("");

  const [formatGeneralEndDate, setFormatGeneralEndDate] = useState<string>("");

  const handleAlertDateChange = (alertStart: Date, alertEnd: Date) => {
    let encodedAlertStartDate = alertStart.toISOString();
    let encodedAlertEndDate = alertEnd.toISOString();
    setFormatAlertStartDate(encodedAlertStartDate);
    setFormatAlertEndDate(encodedAlertEndDate);
  };

  const handleGeneralDateChange = (generalStart: Date, generalEnd: Date) => {
    //console.log("General Start:", generalStart);
    //console.log("General End:", generalEnd);
    let encodedGeneralStartDate = generalStart.toISOString();
    let encodedGeneralEndDate = generalEnd.toISOString();
    setFormatGeneralStartDate(encodedGeneralStartDate);
    setFormatGeneralEndDate(encodedGeneralEndDate);
  };

  const transformBackendData = (
    generalDataResponse: GeneralServiceResponse
  ) => {
    interface MetricValue {
      value: number;
      recvTime: Date;
    }
    const temperaturaData: MetricValue[] = [];
    const conductividadData: MetricValue[] = [];
    const turbidezData: MetricValue[] = [];

    generalDataResponse.forEach((item) => {
      const { temperatura, conductividad, turbidez, recvTime } = item;
      temperaturaData.push({ value: temperatura, recvTime });
      conductividadData.push({ value: conductividad, recvTime });
      turbidezData.push({ value: turbidez, recvTime });
    });

    return {
      temperatura: temperaturaData,
      conductividad: conductividadData,
      turbidez: turbidezData,
    };
  };

  const rows: ServiceResponse = useGetAlerts(
    "flowers_island_context",
    formatAlertStartDate,
    formatAlertEndDate,
    startLoading,
    stopLoading,
    hasLoadedData,
    noLoadedData
  );

  const generalDataResponse: GeneralServiceResponse = useGetGeneralData(
    "flowers_island_context",
    formatGeneralStartDate,
    formatGeneralEndDate
  );

  const transformedDataSets = transformBackendData(generalDataResponse);

  return (
    <section>
      <Grid
        container
        rowSpacing={1}
        spacing={0}
        columns={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={2}>
          <DashboardIcon title="ANÁLISIS HISTÓRICO" />
        </Grid>
        <Grid item xs="auto">
          <DateRangePicker />
        </Grid>
      </Grid>

      <Card title={"ISLA DE FLORES"}>
        <Card title={"ALERTAS"}>
          <Grid container rowSpacing={1} spacing={2} justifyContent="center">
            <Grid item xs={6} paddingTop={"1rem"}>
              <MapComponent />
            </Grid>
            <Grid item xs={6}>
              <DataTableHistory
                title="ALERTAS"
                rows={rows}
                loading={loadingData}
                loaded={loadedData}
              />
            </Grid>
          </Grid>
        </Card>

        <Grid item xs={12}>
          <Grid container rowSpacing={1} spacing={0} columns={12}>
            <Grid item xs={12}>
              <Card
                title={"EVOLUCIÓN DE PARÁMETROS"}
                filters={filterGeneralDate}
                filtersClear={clearFilterGeneralDate}
              >
                <HistoricalEvolutionGraph
                  title="EVOLUCIÓN DE TEMPERATURA"
                  dataSet={transformedDataSets.temperatura}
                  specificDate={selectedDate}
                  borderColor="#18265a"
                  backgroundColor="#18265a"
                />
                <HistoricalEvolutionGraph
                  title="EVOLUCIÓN DE CONDUCTIVIDAD"
                  dataSet={transformedDataSets.conductividad}
                  specificDate={selectedDate}
                  borderColor="#C21292"
                  backgroundColor="#C21292"
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </section>
  );
};

export default HistoricalDataPage;
